import { useEffect } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  coordinateTile: {
    '&:before': {
      content: 'attr(data-coordinates)',
      color: '#FFFFFF',
      display: 'inline-block',
      margin: [4, 0, 0, 4],
      padding: 2,
      fontSize: 10,
      lineHeight: '10px',
      fontFamily: 'Roboto Mono, monospace',
      borderRadius: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.6)'
    }
  }
});

const CoordinateLayer = ({ className }) => {
  const classes = useStyles();
  const context = useLeafletContext();

  useEffect(() => {
    const CoordinateGridLayer: typeof L.GridLayer = L.GridLayer.extend({ 
      createTile: (coords) => {
        const tile = L.DomUtil.create('div', classes.coordinateTile);
        const zModifier = 2**(6 - coords.z);
        tile.setAttribute('data-coordinates', `(${coords.x * zModifier},${coords.y * zModifier})`);
        return tile;
      }
    });

    const container = context.layerContainer || context.map;
    const layer = new CoordinateGridLayer({ 
      tileSize: 100,
      className
    });

    container.addLayer(layer);

    return () => {
      container.removeLayer(layer);
    }
  }, [])

  return null;
};

export default CoordinateLayer;
