import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { 
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import React, { ReactNode, useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  input: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1 auto',
    '& input': {
      boxSizing: 'border-box',
      padding: [4, 4, 4, 7],
      fontSize: 14,
      borderRadius: 3,
      width: '100%',
      color: '#222',
      border: 'solid 1px #222',
      fontFamily: 'Roboto',
      textOverflow: 'ellipsis',
      outline: 0,
      '&:read-only': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        cursor: 'pointer',
      }
    },
    '& label': {
      fontFamily: 'Roboto',
      cursor: 'pointer'
    }
  },
  inputWrapper: {
    position: 'relative'
  },
  hasIcon: {
    paddingRight: [20, '!important']
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 4,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    height: '100%'
  },
  labelWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 4
  },
  collapseIcon: {
    cursor: 'pointer',
    color: '#999',
    '&:hover': {
      color: '#222'
    }
  },
  hiddenInput: {
    display: 'none'
  }
});

type InputProps = {
  label?: string,
  readOnly?: boolean,
  value: string | number,
  onChange: ({ target }) => void,
  onClick?: () => void,
  id?: string,
  icon?: ReactNode,
  collapsable?: boolean,
  defaultCollapsed?: boolean
};

const getDefaultCollapsed = (id, defaultCollapsed) => {
  const savedCollapsed = window.localStorage.getItem(`saved-${id}`);
  if (!savedCollapsed) {
    return defaultCollapsed;
  }

  return  savedCollapsed === 'true';
};

const Input = ({
  label,
  value,
  onChange,
  onClick,
  id,
  readOnly,
  icon,
  collapsable,
  defaultCollapsed
}: InputProps) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(getDefaultCollapsed(id, defaultCollapsed));

  useEffect(() => {

  }, []);

  const toggleCollapsed = () => {
    window.localStorage.setItem(`saved-${id}`, `${!collapsed}`);
    setCollapsed(!collapsed)
  };

  const uncollapseIfCollapsed = () => {
    if (collapsed && collapsable) {
      setCollapsed(false);
    }
  }

  return (
    <div className={classes.input}>
      <div className={classes.labelWrapper} onClick={uncollapseIfCollapsed}>
        <label htmlFor={id}>{label}</label>
        {
          collapsable ?
            <FontAwesomeIcon 
              className={classes.collapseIcon}
              icon={collapsed ? faChevronDown : faChevronUp} 
              onClick={toggleCollapsed}
            />
            : null
        }
      </div>
      <div 
        className={
          classNames({
            [classes.inputWrapper]: true,
            [classes.hiddenInput]: collapsed && collapsable
          })
        }
      >
        <input 
          id={id} 
          value={value} 
          readOnly={readOnly}
          onChange={onChange} 
          onClick={onClick}
          className={
            classNames({
              [classes.hasIcon]: !!icon
            })
          }
        />
        <div className={classes.icon}>
          {icon}
        </div>
      </div>
    </div>
  )
};

export default Input;