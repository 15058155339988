import { useEffect, ReactChild } from 'react';
import { createRoot } from 'react-dom/client';

type IconOverrideProps = {
  selector: string,
  children: ReactChild
}

const IconOverride = ({ selector, children }: IconOverrideProps) => {
  useEffect(() => {
    const rootDOM: HTMLElement = document.querySelector(selector);
    const root = createRoot(rootDOM);
    rootDOM.style.backgroundImage = 'none';
    rootDOM.textContent = '';
    root.render(
      <div 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
          padding: '6px'
        }}
      >
        {children}
      </div>
    );

    return () => {
      root.unmount();
    }
  }, []);

  return null;
}

export default IconOverride