import { useEffect } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  gridTile: {
    borderStyle: 'solid',
    borderColor: 'rgba(255, 255, 255, 0.5)',
    borderWidth: [1, 1, 0, 0]
  }
});

const GridLayer = ({ className }) => {
  const classes = useStyles();
  const context = useLeafletContext();

  useEffect(() => {
    const GridLayer: typeof L.GridLayer = L.GridLayer.extend({ 
      createTile: () => {
        const tile = L.DomUtil.create('div', classes.gridTile);
        return tile;
      }
    });

    const container = context.layerContainer || context.map;
    const layer = new GridLayer({ 
      tileSize: 100,
      className
    });

    container.addLayer(layer);

    return () => {
      container.removeLayer(layer);
    }
  }, [])

  return null;
};

export default GridLayer;