import { ReactNode, useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';

type ControlsPotal = {
  children: ReactNode,
  position?: L.ControlPosition
};

const ControlsPortal = ({ children, position = 'topleft' }: ControlsPotal) => {
  const newControl = useRef(null);
  const [newControlDOMNode, setNewControlDOMNode] = useState(null);
  const context = useLeafletContext();

  useEffect(() => {
    const Control = L.Control.extend({ 
      options: {
        position: 'topleft'
      },
      onAdd: () => {
        const domNode = L.DomUtil.create('div');
        L.DomEvent.disableClickPropagation(domNode);
        L.DomEvent.disableScrollPropagation(domNode);
        setNewControlDOMNode(domNode);
        return domNode;
      }
    });

    const container = context.map;
    newControl.current = new Control({ position });
    
    container.addControl(newControl.current);
    return () => {
      container.removeControl(newControl.current);
    }
  }, [])

  useEffect(() => {
    newControl.current.setPosition(position);
  }, [position])

  if (!newControlDOMNode) {
    return null;
  }

  return ReactDOM.createPortal(children, newControlDOMNode);
};

export default ControlsPortal;