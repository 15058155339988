import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { 
  faPenToSquare, 
  faFloppyDisk, 
  faBan,
  faGripLines
} from '@fortawesome/free-solid-svg-icons'
import { createUseStyles } from 'react-jss';
import Input from '../../Input';

const useStyles = createUseStyles({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 6,
    padding: [4, 6],
    width: '100%',
    boxSizing: 'border-box'
  },
  hidden: {
    display: 'none'
  },
  iconButton: {
    cursor: 'pointer',
    fontSize: 20,
    color: '#999',
    '&:hover': {
      color: '#222'
    }
  },
  selected: {
    backgroundColor: '#E4E4E4'
  },
  merged: {
    opacity: 0.5
  },
  disabled: {
    '& input': {
      cursor: ['default', '!important']
    }
  }
});

type SegmentSelectorOptionProps = {
  onSelect?: () => void,
  selected?: boolean,
  onEdit?: (newValue: string) => void,
  children: string,
  merged?: boolean,
  isVisible?: boolean,
  disabled?: boolean
}

const SegmentSelectorOption = ({ 
  onSelect, 
  onEdit,
  selected,
  children,
  merged,
  isVisible,
  disabled
}: SegmentSelectorOptionProps) => {
  const classes = useStyles();
  const [optionValue, setOptionValue] = useState(children);
  const oldOptionValue = useRef(optionValue);
  const [editable, setEditable] = useState(false);
  const [mouseIsOver, setMouseIsOver] = useState(false);

  const updateOptionValue = ({ target }) => {
    setOptionValue(target.value);
  };

  const handleClick = () => {
    if (!editable) {
      onSelect();
    }
  };

  const makeEditable = () => {
    setEditable(true);
  };

  const cancelEdit = () => {
    setOptionValue(children);
    setEditable(false);
  };

  useEffect(() => {
    if (!isVisible && editable) {
      cancelEdit();
    }
  }, [isVisible]);

  const saveEdit = () => {
    if (optionValue !== oldOptionValue.current) {
      onEdit(optionValue);
      oldOptionValue.current = optionValue;
    }
    setEditable(false);
  };

  return (
    <div
      onMouseOver={() => setMouseIsOver(true)}
      onMouseOut={() => setMouseIsOver(false)}
      className={
        classNames({
          [classes.option]: true,
          [classes.selected]: !!selected,
          [classes.merged]: !!merged,
          [classes.disabled]: !!disabled
        })
      }
    >
      <FontAwesomeIcon 
        className={
          classNames({
            [classes.iconButton]: true,
            [classes.hidden]: merged || disabled || true
          })
        } 
        icon={faGripLines} 
      />
      <Input 
        value={optionValue} 
        onChange={updateOptionValue} 
        readOnly={!editable || !!disabled} 
        onClick={handleClick}
      />
      <FontAwesomeIcon 
        className={
          classNames({
            [classes.iconButton]: true,
            [classes.hidden]: editable || merged || disabled || !mouseIsOver
          })
        }
        icon={faPenToSquare} 
        onClick={makeEditable} 
      />
      <FontAwesomeIcon 
        className={
          classNames({
            [classes.iconButton]: true,
            [classes.hidden]: !editable || merged || disabled
          })
        }
        icon={faFloppyDisk} 
        onClick={saveEdit} 
      />
      <FontAwesomeIcon 
        className={
          classNames({
            [classes.iconButton]: true,
            [classes.hidden]: !editable || merged || disabled
          })
        }
        icon={faBan} 
        onClick={cancelEdit} 
      />
    </div>
  )
};

export default SegmentSelectorOption;