import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl } from 'react-leaflet';
import L from 'leaflet';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLayerGroup,
  faPlus,
  faMinus
} from '@fortawesome/free-solid-svg-icons'

import GridLayer from './GridLayer';
import CoordinateLayer from './CoordinateLayer';
import ControlsPortal from './ControlsPortal';
import SegmentSelector from './SegmentSelector';
import IconOverride from './IconOverride';
import URIController from './URIController';
import config from '../../../config.json';

const useStyles = createUseStyles({
  mapContainer: {
    height: '100vh',
    width: '100vw'
  },
  iconOverride: {
    width: 26,
    height: 26, 
    color: '#222'
  },
  '@global': {
    '.leaflet-control-layers-overlays label': {
      fontSize: 16,
      cursor: 'pointer',
    },
    '.leaflet-control-attribution': {
      display: 'none'
    },
    '.leaflet-control-zoom': {
      borderRadius: 5
    },
    '.leaflet-control-zoom-in': {
      borderBottomWidth: [2, '!important']
    },
    '.leaflet-control-zoom-out, .leaflet-control-zoom-in': {
      width: [44, '!important'],
      height: [44, '!important'],
    },
    '.leaflet-disabled': {
      '& $iconOverride': {
        color: '#AAA'
      }
    },
  },
  coordinatesLayer: {},
  gridLayer: {},
  hidden: {
    display: 'none'
  }
});

const getSavedSegmentId = () => {
  let segmentId = window.localStorage.getItem('saved-segmentId');

  const pathnameParts = window.location.pathname.split('/');

  if (pathnameParts.length === 6) {
    segmentId = pathnameParts[2];
  }

  if (segmentId) {
    return segmentId;
  }

  return null;
};

const MapPage = () => {
  const tileSize = useRef(100);
  const savedSegmentId = useRef(getSavedSegmentId())
  const classes = useStyles();
  const [selectedSegmentId, setSelectedSegmentId] = useState(savedSegmentId.current);
  const [segmentData, setSegmentData] = useState(null);

  const hideOverlays = ({ key }) => {
    if (key === 'Control') {
      const coordinateLayerElement = document.querySelector(`.${classes.coordinatesLayer}`);
      const gridLayerElement = document.querySelector(`.${classes.gridLayer}`);

      if (coordinateLayerElement || gridLayerElement) {
        coordinateLayerElement?.classList.add(classes.hidden);
        gridLayerElement?.classList.add(classes.hidden);
      }
    }
  };

  const showOverlays = ({ key }) => {
    if (key === 'Control') {
      document.querySelector(`.${classes.coordinatesLayer}`)?.classList.remove(classes.hidden);
      document.querySelector(`.${classes.gridLayer}`)?.classList.remove(classes.hidden);
    }
  };

  useEffect(() => {
    fetch(`${config.domain}map/segmentData`)
      .then(response => response.json())
      .then((segmentData) => {
        const { segmentIds } = segmentData;
        setSegmentData(segmentData);

        if (selectedSegmentId === null || !segmentIds.includes(selectedSegmentId)) {
          selectSegmentId(segmentIds[0]);
        }
      });
    
    document.addEventListener('keydown', hideOverlays);
    document.addEventListener('keyup', showOverlays);

    return () => {
      document.removeEventListener('keydown', hideOverlays);
      document.removeEventListener('keyup', showOverlays);
    }
  }, []);

  if (!selectedSegmentId) {
    return null;
  }

  const selectSegmentId = (segmentId) => {
    if (segmentId) {
      window.localStorage.setItem('saved-segmentId', segmentId);
      setSelectedSegmentId(segmentId);
    } else {
      setSelectedSegmentId(null);
    }
    
  };

  return (
    <MapContainer 
      className={classes.mapContainer}
      center={[0, 0]} 
      crs={L.CRS.Simple} 
      zoom={6} 
      minZoom={0}
      maxZoom={8}
    >
      <TileLayer 
        key={selectedSegmentId}
        zIndex={0}
        url={`${config.domain}map/tile/${selectedSegmentId}/{x}/{y}/{z}`} 
        tileSize={tileSize.current}
        maxNativeZoom={6}
      />
      <LayersControl position="topleft">
        <LayersControl.Overlay name="Grid" checked>
          <GridLayer className={classes.gridLayer} />
        </LayersControl.Overlay>
        <LayersControl.Overlay name="Coordinates" checked>
          <CoordinateLayer className={classes.coordinatesLayer} />
        </LayersControl.Overlay>
      </LayersControl>
      <ControlsPortal>
        <SegmentSelector 
          segmentData={segmentData} 
          setSelectedSegmentId={selectSegmentId}
          selectedSegmentId={selectedSegmentId}
        />
      </ControlsPortal>
      <IconOverride selector=".leaflet-control-layers-toggle">
        <FontAwesomeIcon className={classes.iconOverride} icon={faLayerGroup} />
      </IconOverride>
      <IconOverride selector=".leaflet-control-zoom-out">
        <FontAwesomeIcon className={classes.iconOverride} icon={faMinus} />
      </IconOverride>
      <IconOverride selector=".leaflet-control-zoom-in">
        <FontAwesomeIcon className={classes.iconOverride} icon={faPlus} />
      </IconOverride>
      <URIController segmentId={selectedSegmentId} tileSize={tileSize.current} />
    </MapContainer>
  );
};

export default MapPage;