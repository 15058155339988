import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap, faBan } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import SegmentSelectorOption from './SegmentSelectorOption';
import Input from '../../Input';
import config from '../../../config.json';

type SegmentSelectorProps = {
    segmentData: any,
    selectedSegmentId: string,
    setSelectedSegmentId: (id: string) => void
};

type SegmentLabel = {
  value: string,
  name: string
};

const useStyles = createUseStyles({
  segmentSelector: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5
  },
  mapIcon: {
    color: '#222',
    width: '26px', 
    height: '26px'
  },
  content: {
    padding: [6, 6, 0, 6],
    width: 200
  },
  filtered: {
    paddingBottom: 6
  },
  collapsed: {
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    width: 44,
    height: 44
  },
  hide: {
    display: 'none'
  },
  options: {
    marginTop: 12,
    overflowY: 'scroll',
    maxHeight: '45vh',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  inputs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    rowGap: 4
  },
  clearButton: {
    cursor: 'pointer',
    color: '#999',
    '&:hover': {
      color: '#222'
    }
  },
});

const getDefaultFilterValue = (filterType) => {
  switch (filterType) {
    case 'include': {
      return window.localStorage.getItem('saved-includeInput') || '';
    }
    case 'exclude': {
      return window.localStorage.getItem('saved-excludeInput') || '';
    }
  }
};

const SegmentSelector = ({ segmentData, setSelectedSegmentId, selectedSegmentId }: SegmentSelectorProps) => {
  const map = useMap();
  const classes = useStyles();
  const optionsElement = useRef(null);
  const closeTimeout = useRef(null);
  const defaultIncludeValue = useRef(getDefaultFilterValue('include'));
  const defaultExcludeValue = useRef(getDefaultFilterValue('exclude'));
  const [isOpen, setIsOpen] = useState(false);
  const [includeInputValue, setIncludeInputValue] = useState(defaultIncludeValue.current);
  const [excludeInputValue, setExcludeInputValue] = useState(defaultExcludeValue.current);
  const [segmentLabels, setSegmentLabels] = useState(new Map());

  useEffect(() => {
    if (segmentData === null) {
      return;
    }

    const { segmentIds, segmentNames, segmentMergeHistory } = segmentData;
    const newSegmentLables = new Map<string, SegmentLabel>(segmentIds.map(id => (
      [id, { value: id, name: id }]
    )));

    segmentMergeHistory.forEach(({ primary, secondary }) => {
      if (!newSegmentLables.has(secondary.id)) {
        newSegmentLables.set(secondary.id, { value: primary.id, name: secondary.id });
      }
    });

    segmentNames.forEach(({ id, name }) => {
      if (newSegmentLables.has(id)) {
        updateSegmentLableMap(newSegmentLables, id, name);
      }
    });
    setSegmentLabels(newSegmentLables);
  }, [segmentData]);

  useEffect(() => {
    optionsElement.current.scrollTo(0, 0);
  }, [isOpen]);

  const saveSegmentName = (segmentId, segmentName) => {
    fetch(`${config.domain}map/setSegmentName`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        segmentId,
        segmentName
      })
    });

    setSegmentLabels((prevSegmentLabels) => {
      updateSegmentLableMap(prevSegmentLabels, segmentId, segmentName);
      return prevSegmentLabels;
    });
  };

  const updateSegmentLableMap = (map, segmentId, segmentName) => {
    map.set(segmentId, {
      ...map.get(segmentId),
      name: segmentName
    });
  };

  const valueIsIncluded = (value) => {
    const evaluatedValue = value.toLowerCase().trim();
    const evaluatedIncludeInputValue = includeInputValue.toLowerCase().trim();

    return evaluatedIncludeInputValue === '' || evaluatedValue.includes(evaluatedIncludeInputValue);
  };

  const valueIsExcluded = (value) => {
    const evaluatedValue = value.toLowerCase().trim();
    const evaluatedExcludeInputValue = excludeInputValue.toLowerCase().trim();

    if (evaluatedExcludeInputValue === '') {
      return false;
    }

    return evaluatedValue.includes(evaluatedExcludeInputValue);
  }

  const handleSegmentSelect = (segmentId) => {
    optionsElement.current.scrollTo(0, 0);
    setSelectedSegmentId(segmentId);
    const coordsAsPoint = L.point(0.5, 0.5);
    const scaledPoint = coordsAsPoint.scaleBy(L.point(100, 100));
    const latLang = map.unproject(scaledPoint, map.getZoom());
    map.setView(latLang, 6);
  };

  const buildOptions = () => {
    const options = [];
    segmentLabels.forEach(({ value, name }, id) => {
      const isSelected = id === selectedSegmentId;

      if (valueIsIncluded(name) && !valueIsExcluded(name) || isSelected) {      
        let appendMethod = 'push';

        if (isSelected) {
          appendMethod = 'unshift';
        }

        options[appendMethod](
          <SegmentSelectorOption
            onSelect={() => handleSegmentSelect(value)}
            onEdit={(newName) => saveSegmentName(id, newName)}
            merged={id !== value}
            selected={isSelected}
            key={id}
            isVisible={isOpen}
          >
            {name}
          </SegmentSelectorOption>
        );
      }
    });

    return options;
  };

  const updateInputValue = (inputType) => (
    ({ target }) => {
      switch (inputType) {
        case 'include': {
          setIncludeInputValue(target.value);
          window.localStorage.setItem('saved-includeInput', target.value);
          break;
        }
        case 'exclude': {
          setExcludeInputValue(target.value);
          window.localStorage.setItem('saved-excludeInput', target.value);
          break;
        }
      }
    }
  );

  const clearInput = (inputType) => (
    () => {
      switch (inputType) {
        case 'include': {
          updateInputValue('include')({ target: { value: '' } });
          break;
        }
        case 'exclude': {
          updateInputValue('exclude')({ target: { value: '' } });
          break;
        }
      }
    }
  );

  return (
    <div 
      className={
        classNames({
          'leaflet-bar': true,
          [classes.segmentSelector]: true
        })
      }
      onMouseOver={() => {
        clearTimeout(closeTimeout.current);
        setIsOpen(true);
      }}
      onMouseOut={() => {
        clearTimeout(closeTimeout.current);
        closeTimeout.current = setTimeout(() => {
          setIsOpen(false);
        }, 500);
      }}
    >
      <div 
        className={
          classNames({
            [classes.collapsed]: true,
            [classes.hide]: isOpen
          })
        }
      >
        <FontAwesomeIcon className={classes.mapIcon} icon={faMap} />
      </div>
      <div
        className={
          classNames({
            [classes.content]: true,
            [classes.filtered]: includeInputValue !== '' || excludeInputValue !== '',
            [classes.hide]: !isOpen
          })
        }
      >
        <div className={classes.inputs}>
          <Input
            label="Include"
            id="include"
            value={includeInputValue}
            onChange={updateInputValue('include')}
            collapsable
            icon={
              includeInputValue !== '' ? 
                <FontAwesomeIcon 
                  className={classes.clearButton} 
                  icon={faBan} 
                  onClick={clearInput('include')} 
                />
                : null
            }
          />
          <Input
            label="Exclude"
            id="exclude"
            value={excludeInputValue}
            onChange={updateInputValue('exclude')}
            collapsable
            defaultCollapsed={true}
            icon={
              excludeInputValue !== '' ? 
                <FontAwesomeIcon 
                  className={classes.clearButton} 
                  icon={faBan} 
                  onClick={clearInput('exclude')} 
                />
                : null
            }
          />
        </div>
        <div 
          className={classes.options}
          ref={optionsElement}
        >
          {buildOptions()}
        </div>
      </div>
    </div>
  );
};

export default SegmentSelector;