import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

import Header from './Header';
import { HomePage, MapPage } from './pages';

const useStyles = createUseStyles({
  '@global': {
    body: {
      margin: 0
    }
  }
});

const App = () => {
  useStyles();
  
  return (
    <div>
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/map/*" element={<MapPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;