import { useEffect, useRef } from 'react';
import { useMapEvents, useMap } from 'react-leaflet';
import L from 'leaflet';

const URIController = ({ segmentId, tileSize }) => {
  const tileSizePoint = useRef(L.point(tileSize, tileSize));
  const map = useMap();

  useEffect(() => {
    if (window.location.pathname.split('/').length < 6) {
      const savedPath = window.localStorage.getItem('saved-view');
      
      if (savedPath) {
        window.history.replaceState(null, null, savedPath);
      }
    }

    const pathNameParts = window.location.pathname.split('/');

    if (pathNameParts.length < 6) {
      return;
    }

    const coordinates = pathNameParts.slice(-3).map(num => +num);
    const [x, y, z] = coordinates;
    const zModifier = 2**(6 - z);
    const coordsAsPoint = L.point(x / zModifier + 0.5, y / zModifier + 0.5);
    const scaledPoint = coordsAsPoint.scaleBy(tileSizePoint.current);
    const latLang = map.unproject(scaledPoint, z);
    map.setView(latLang, z);
  }, []);

  useMapEvents({
    moveend: e => {
      const coords = getCoords(e.target);
      updateURI(coords.x, coords.y, coords.z);
    },
  });

  const getCoords = (target) => {
    const zoomLevel = target.getZoom();
    const pixelPoint = target.project(
      target.getCenter(), 
      zoomLevel
    ).floor();

    const unscaledPoint = pixelPoint.unscaleBy(tileSizePoint.current).floor();
    unscaledPoint.z = zoomLevel;

    return unscaledPoint;
  };

  useEffect(() => {
    const {x, y, z} = getCoords(map);
    updateURI(x, y, z);
  }, [segmentId]);

  const updateURI = (x, y, z) => {
    const zModifier = 2**(6 - z);
    const path = `/map/${segmentId}/${x * zModifier}/${y * zModifier}/${z}`;
    window.localStorage.setItem('saved-view', path);
    window.history.replaceState(null, null, path);
  };

  return null;
};

export default URIController;